$borg-dark: rgb(14, 3, 0);
$orange: rgb(255, 182, 61);
$orange-faded: rgba(255, 182, 61, 0.8);
$auria-yellow: #FFBC46;
$blue: rgba(87,236,243,1);
$red: rgb(243, 87, 87);
$border-gray: #515151;
$component-gray: #1C1C1C;
$box-outer-margin: 20px;
$box-inner-margin: 20px;

html {
  // a full-page background that doesn't scroll or repeat
  background-image: url(./assets/img/screen_bg.png);
  background-size: cover;
  background-attachment: fixed;
}

body {
  // minimum body height so footer appears on bottom (without resorting to fixed/absolute) of screen
  min-height: 380px;
}

.box-controller {
    visibility: visible;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 24em;
    min-width: 21em;
    height: 35px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
    box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
    align-items: center;
    transition: visibility 0.5s ease-out;
    margin: 3em;
}

.borg-logo {
    max-width: 28em;
    display: flex;
    flex-direction: column;
    margin-left: 3vw;
    margin-right: 3vw;
}

.borg-logo-mediumsize {
    max-width: 22em;
    display: flex;
    flex-direction: column;
    margin-left: 3vw;
    margin-right: 3vw;
}

.borg-logo-appbar {
    max-width: 11em;
    display: flex;
    flex-direction: column;
    margin-right: 1em;
}

// default to column display, best if the window is tall enough
.main {
    display: flex;
    flex-direction: column;
    margin: 2em;
    margin-top: 6vh;
    justify-content: center;
    align-items: center;
    row-gap: 2em;
}

// if screen is not tall enough AND it's wide enough, use row display
//@media screen and (max-height: 650px) and (min-width: 900px) {
@media screen and (min-aspect-ratio: 7/3) {
  .main {
      flex-direction: row;
      margin-top: 12vh;
  }
}

// default to column display, best if the window is tall enough
.main-register {
  display: flex;
  flex-direction: column;
  margin: 2em;
  justify-content: center;
  align-items: center;
  row-gap: 2em;
}

// if screen is not tall enough AND it's wide enough, use row display
//@media screen and (max-height: 650px) and (min-width: 900px) {
@media screen and (min-aspect-ratio: 4/3) {
  .main-register {
      flex-direction: row;
  }
}

.unityviewer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.link-button {
    color: $orange-faded;
    text-decoration: none;
}

.link-button:hover {
    color: $orange;
    text-decoration: underline;
}

.text-blue {
  color: $blue;
}

.loading {
    text-align: center;
    // font-family: Oxygen, sans-serif;
    font-size: 36px;
    color: $blue;
    margin-top: 2rem;
}

.selected-controller {
    transition: border 0.5s ease-out;
    border-bottom: 2px solid $blue;
}

.box-container {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 20em;
    margin-left: 1vw;
    margin-right: 1vw;
    // box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
    border-radius: 6px;
    padding: 24px;
    background-color: black;
    border-color: $border-gray;
    border-style: solid;
    gap: $box-outer-margin;
}

.inner-container {
    transition: visibility 0.2s ease-out;
}

.inner-container.show {
    visibility: visible;
}

.header {
    text-align: center;
    margin-left: $box-outer-margin;
    margin-right: $box-outer-margin;
    // font-family: Oxygen, sans-serif;
    font-size: 22px;
    border-bottom: 2px solid $blue;
    color: $blue;
}

.admin-page-container-content{
  display: flex;
  height: calc(100vh - 70.500px); // Subtract 70.500px for the AdminAppBar size
}

.admin-main-content{
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
}

.admin-sidebar{
  width: auto;
  min-width: 200px;
}

.banner {
  text-align: center;
  background-color: orange;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
}

.banner-modal {
  background-color: orange;
}

.system-section {
  background-color: $component-gray;
  padding: 16px;
  padding-top: 6px; //Even out the top spacing with the left, right, and bottom
  border-radius: 6px;
  border-color: $border-gray;
  border-style: solid;
  margin-top: 16px;
}

.footer {
    text-align: center;
}

.login-footer-fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
    z-index: -1;
}

.login-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}

.copyright {
  text-align: center;
  font-size: 12px;
}

.box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: $box-outer-margin;
    flex-wrap: wrap;
}

.box-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: $box-inner-margin;
}

.box-column-dense {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: $box-inner-margin/4;
    
    text-overflow: ellipsis;
}

.login-label {
    // font-family: Oxygen, sans-serif;
    font-size: 20px;
    font-weight: 600;
    padding-left: 5px;
    color:rgb(255, 182, 61);
}

.error {
    color: red;
    font-size: 10pt;
    text-align: center;
}

.secondary-btn {
    padding: 2px 30px;
    border: 0;
    font-size: 18px;
    border-radius: 3px;
    // font-family: Oxygen, sans-serif;
    border: 2px solid $blue;
    border-color: $blue;
    color: $blue;
    background-color: black;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
    min-width: 10em;
}

.secondary-btn-fitted {
  padding: 2px 30px;
  border: 0;
  font-size: 18px;
  border-radius: 3px;
  // font-family: Oxygen, sans-serif;
  border: 2px solid $blue;
  border-color: $blue;
  color: $blue;
  background-color: black;
  transition: background-color 0.3s ease-out;
  cursor: pointer;
}

.secondary-btn-red {
  padding: 2px 30px;
  border: 0;
  font-size: 18px;
  border-radius: 3px;
  border: 2px solid $red;
  border-color: $red;
  color: $red;
  background-color: black;
  transition: background-color 0.3s ease-out;
  cursor: pointer;
  min-width: 10em;
}

// override browser's autofill color for input fields since white is too
// jarring on BORG's dark theme
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
   -webkit-box-shadow: 0 0 0 30px rgba($borg-dark, 0.85) inset !important;
   -webkit-text-fill-color: $orange;
}

.secondary-btn:hover {
    border-color: $orange;
    color: $orange;
}

.secondary-btn-fitted:hover {
    border-color: $orange;
    color: $orange;
}

.secondary-btn-red:hover {
  border-color: $orange;
  color: $orange;
}

.danger-error {
    color: #e74c3c;
    font-size: 16px;
}

.password-state {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.pwd {
    height: 6px;
    flex: 1;
    visibility: hidden;
}

.pwd-weak {
    background-color: #e74c3c;
}

.pwd-medium {
    background-color: #3498db;
}

.pwd-strong {
    background-color: #2ecc71;
}

.show {
    visibility: visible;
}


  .price {
    font-size: 48px;
    font-weight: bold;
    color: $auria-yellow;
  
    @media (max-width: 768px) {
        font-size: 28px;
    }
  }


  .billing,
  .cancel {
    font-size: 30px;
    color: #ffffff;

    @media (max-width: 768px) {
        font-size: 16px;
      }
  }

  h3 {
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 20px;
      }
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  li {
    font-size: 18px;
    color: #ffffff;
    position: relative;

    @media (max-width: 768px) {
        font-size: 14px;
      }
  }

  li::before {
    content: "";
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 2px;
    background-color: $auria-yellow;
  }

  .purchase-registration-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
    margin-left: 3vw;
    margin-right: 3vw;
    min-width: 30em;
    width: 55vw;
    // box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
    border-radius: 6px;
    padding: 24px;
    background-color: black;
    border-color: $border-gray;
    border-style: solid;
    gap: $box-outer-margin;
  }

  .purchase-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .purchase-button {
    padding: 0.5rem 1rem;
    border: 2px solid $blue;
    border-radius: 3px;
    font-size: 18px;
    color: $blue;
    background-color: black;
    transition: background-color 0.3s ease-out;
    cursor: pointer;
    margin-top: 1rem;
    display: block;
    margin: auto;
  
    &:hover {
      border-color: $orange;
      color: $orange;
    }
  }

  .purchase-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  
  }

  .purchase-box-column {
    width: 48%;
  
  }

  .buttons-container {
    display: flex;
    justify-content: space-evenly;
    padding: 1rem;
  }
  
  .stripe-textfield {
    position: relative;
    padding: 18px;
    width: 250px;
  }
  
  .stripe-textfield::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: $orange;
  }
  
  .stripe-textfield:hover::before {
    background-color: $blue;
  }
  