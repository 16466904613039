@import '../../GlobalStyles/_globalVariables.scss'; 

.unauthorized  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    color: white;
    flex-direction: column;
    .errorCode{
        font-size: 18rem;
        font-family: Oxygen, sans-serif;
        margin: 0;
        //gradient from primary to secondary 
        background: url(../../assets/img/nebula.jpg) no-repeat center center fixed;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transform: scale(1.5, 1);
    }
    .errorMessage{
        font-size: 2rem;
        font-family: Oxygen, sans-serif;
        margin: 0;
        text-align: center;
    }
    .errorHelp{
        font-size: 1.5rem;
        font-family: Oxygen, sans-serif;
        margin: 0;
        text-align: center;
    }
    .errorButton{
        background-size: 200% auto;
        color: white;
        border-radius: 4px;
        border-color: $color-primary;
        //gradient border
        border: 4px solid $color-primary;
        margin-top: 2em;
        font-family: Oxygen, sans-serif;
        text-transform: uppercase;
        padding: 12px 24px;
        transition: ease-out 0.3s;
        z-index: 1;
        position: relative;
        &:hover{
            color: black;
        }
        &:before{
            transition: .5s all ease;
            position: absolute;
            top: 0;
            left: 50%;
            right: 50%;
            bottom: 0;
            opacity: 0;
            background-color: $color-primary;
            content: '';
        }
        &:hover:before{
            transition: .5s all ease;
            left: 0;
            right: 0;
            opacity: 1;
            z-index: -1;
        }
    }
}